import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { AXIOS_TIMEOUT } from '../../config';
import { getPersonalDoctor } from '../../url';
export function useGetPersonalDoctor(option) {
    const { get } = useWellcareApi();
    const { execute: executeGetPersonalDoctor, onSucess: onSuccessGetPersonalDoctor, loading } = useRepository({
        fetcher: () => {
            return get({
                url: getPersonalDoctor(option.value),
                timeout: AXIOS_TIMEOUT,
                params: {
                    populate: JSON.stringify([{ path: 'related' }, { path: 'provider' }]),
                    count: true,
                    pageSize: 10,
                    pageIndex: 1
                }
            });
        },
        useFetch: false,
        toastOnError: true
    });
    return {
        loading,
        executeGetPersonalDoctor,
        onSuccessGetPersonalDoctor
    };
}
