var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// @ts-nocheck
/* eslint-disable */
import { Component, Prop, Vue } from 'vue-property-decorator';
let TextIcon = class TextIcon extends Vue {
    mounted() {
        this.$nextTick(() => {
            const textIcon = this.$refs.textIcon;
            textIcon.classList.value = 'v-icon w-text-size';
        });
    }
};
__decorate([
    Prop({ required: true })
], TextIcon.prototype, "text", void 0);
TextIcon = __decorate([
    Component({})
], TextIcon);
export default TextIcon;
