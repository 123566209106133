import { computed, useRoute } from '@nuxtjs/composition-api';
import { useElasticSearch } from '@wellcare/nuxt-module-notion/repositories';
export const useSearchPage = () => {
    const route = useRoute();
    const query = computed(() => {
        var _a;
        return {
            query: {
                bool: {
                    filter: [
                        {
                            term: {
                                'page.id.keyword': {
                                    value: (_a = route.value.params) === null || _a === void 0 ? void 0 : _a.id
                                }
                            }
                        }
                    ]
                }
            }
        };
    });
    const { hits: pages, execute: executeSearchPage } = useElasticSearch('notion-edu-hub', query);
    return { pages, executeSearchPage };
};
