export const CONTENTS = {
    FAQ: {
        vi: '/content/khamtuxa.vn/cau-hoi-thuong-gap',
        en: '/content/khamtuxa.vn/frequently-asked-questions'
    },
    TERMS_AND_CONDITIONS: {
        vi: '/content/khamtuxa.vn/dieu-khoan-and-dieu-kien-su-dung',
        en: '/content/khamtuxa.vn/terms-and-conditions'
    },
    PRIVACY_POLICY: {
        vi: '/content/khamtuxa.vn/bao-mat-thong-tin',
        en: '/content/khamtuxa.vn/privacy-policy'
    }
};
export const SUPPORT_CONTACTS = {
    CARE_TEAM: 'https://zalo.me/2727084330920973261',
    ACCOUNT_MANAGER: 'https://zalo.me/0366905905',
    ZALO: 'https://zalo.me/2727084330920973261',
    VIBER: 'viber://add?number=84366905905',
    WHATSAPP: 'https://wa.me/84366905905',
    MAIL: 'cskh@khamtuxa.vn'
};
export const APP_LINKS = {
    ANDROID: 'https://play.google.com/store/apps/details?id=vn.wellcare',
    IOS: 'https://apps.apple.com/us/app/wellcare/id1039423586'
};
