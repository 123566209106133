export const popularExpertsQuery = {
    _source: ['output.*'],
    sort: [
        {
            'score.rating': 'desc'
        }
    ],
    query: {
        bool: {
            filter: [
                {
                    terms: {
                        'output.type.keyword': ['consultation-v2']
                    }
                },
                {
                    terms: {
                        'output.state': ['published']
                    }
                }
            ],
            must: [],
            should: []
        }
    },
    from: 0,
    size: 5
};
export const newExpertsQuery = {
    _source: ['output.*'],
    sort: [
        {
            'score.rating': 'desc'
        }
    ],
    query: {
        bool: {
            filter: [
                {
                    terms: {
                        'output.type.keyword': ['consultation-v2']
                    }
                },
                {
                    terms: {
                        'output.state': ['published']
                    }
                },
                {
                    terms: {
                        'search.inCollections.keyword': ['new-experts']
                    }
                }
            ],
            must: [],
            should: []
        }
    },
    highlight: {
        pre_tags: ['<span class="yellow">'],
        post_tags: ['</span>'],
        fields: {
            'output.name': {},
            'output.provider.specialty.vi.title': {},
            'output.provider.specialty.en.title': {}
        }
    },
    from: 0,
    size: 6
};
