import { defineComponent, computed } from '@nuxtjs/composition-api';
import { StatusBar, Style } from '@capacitor/status-bar';
import WAppBar from '../components/app-bar.vue';
import useDeepLink from '../composables/use-deeplink';
import { useAppBar } from '~/composables/use-app-bar';
import useApp from '~/composables/use-app';
export default defineComponent({
    components: { WAppBar },
    setup() {
        useDeepLink();
        const { title, visibility } = useAppBar();
        const { padding } = useApp();
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px',
            height: '100%'
        }));
        try {
            StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
        }
        catch (e) { }
        return { title, visibility, layoutStyle, padding };
    }
});
