var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],style:({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      zIndex: '999'
    })},[_c('div',{staticClass:"loading"},[_c('svg',{attrs:{"width":"16px","height":"12px"}},[_c('polyline',{attrs:{"id":"back","points":"1 6 4 6 6 11 10 1 12 6 15 6"}}),_vm._v(" "),_c('polyline',{attrs:{"id":"front","points":"1 6 4 6 6 11 10 1 12 6 15 6"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }