import { useLog } from '@wellcare/vue-component';
const useClarity = ({ platform }) => {
    const PROJECT_ID = 'h4ac6xa98f';
    const { log } = useLog();
    const onClarityInitSuccess = (message) => {
        log({
            message: `Clarity init success.`,
            context: 'ClarityPlugin.initialize',
            data: { message }
        });
    };
    const onClarityInitFail = (message) => {
        log({
            message: `Clarity init fail.`,
            context: 'ClarityPlugin.initialize',
            data: { message }
        });
    };
    const onSetCustomUserIdSuccess = (message) => {
        log({
            message: `Clarity set custom user id success.`,
            context: 'ClarityPlugin.setCustomUserId',
            data: { message }
        });
    };
    const onSetCustomUserIdFail = (message) => {
        log({
            message: `Clarity set custom user id fail.`,
            context: 'ClarityPlugin.setCustomUserId',
            data: { message }
        });
    };
    const initialize = () => {
        // Clarity currently supported only web and android. Ref: https://clarity.microsoft.com
        if (platform !== 'android')
            return onClarityInitFail('IOS have not implemented yet');
        ClarityPlugin.initialize(PROJECT_ID, onClarityInitSuccess, onClarityInitFail, true);
    };
    const setCustomUserId = (userId) => {
        // Clarity currently supported only web and android. Ref: https://clarity.microsoft.com
        if (platform !== 'android')
            return onSetCustomUserIdFail('IOS have not implemented yet');
        ClarityPlugin.setCustomUserId(userId, onSetCustomUserIdSuccess, onSetCustomUserIdFail);
    };
    return { initialize, setCustomUserId };
};
export default useClarity;
