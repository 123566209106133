import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    providers: [],
    providerChosen: {},
    isMenuOpened: false
});
const getters = {
    getField
};
const mutations = {
    updateField,
    SET_PROVIDERS(state, providers) {
        state.providers = providers;
    },
    SET_STATE_MENU(state, stateMenu) {
        state.isMenuOpened = stateMenu;
    },
    SET_PROVIDER_CHOSEN(state, data) {
        state.providerChosen = data;
    }
};
const actions = {};
export default {
    namespaced: true,
    strict: false,
    state,
    getters,
    mutations,
    actions
};
