import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import updateLocale from 'dayjs/plugin/updateLocale';
export default defineNuxtPlugin(({ app }) => {
    const { $dayjs } = app;
    $dayjs.extend(updateLocale);
    $dayjs.updateLocale('vi', {
        calendar: {
            sameDay: '[Hôm nay] h:mm A',
            nextDay: '[Ngày mai] h:mm A',
            nextWeek: 'dddd h:mm A',
            lastDay: '[Hôm qua] h:mm A',
            lastWeek: 'dddd [tuần trước] h:mm A',
            sameElse: 'DD/MM/YYYY' // Everything else ( 17/10/2011 )
        }
    });
});
