/* eslint-disable */
import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { walletJournalTransaction } from '../url';
import { AXIOS_TIMEOUT } from '../config';
export function useWalletJournalTransaction() {
    const { get } = useWellcareApi();
    const { execute: executeWalletJournalTransaction, onSucess: onGetWalletJournalTransaction, loading: walletJournalTransactionLoading } = useRepository({
        fetcher: (param) => {
            return get({
                url: walletJournalTransaction(),
                timeout: AXIOS_TIMEOUT,
                params: {
                    filter: {
                        account: param._id
                    },
                    fields: 'account,event,state,amount.value,occurredAt,reason,sign,description',
                    sort: '-occurredAt',
                    populate: [
                        { path: 'createdBy', select: 'search' },
                        { path: 'updatedBy', select: 'search' },
                        { path: 'account', select: 'display' },
                        { path: 'toAccount', select: 'display' }
                    ],
                    count: true,
                    pageSize: 10,
                    pageIndex: 1
                }
            });
        },
        useFetch: false,
        toastOnError: true
    });
    return {
        executeWalletJournalTransaction,
        onGetWalletJournalTransaction,
        walletJournalTransactionLoading
    };
}
