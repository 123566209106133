import { computed, onBeforeMount, onMounted, useContext } from '@nuxtjs/composition-api';
import { useUser } from '@wellcare/nuxt-module-account/repositories';
import { usePregnancy } from '@wellcare/nuxt-module-checkout/repositories';
import { useAppBar, useHealthPrograms } from '~/composables';
import { useZepMemory } from '~/repositories';
const convertLabels = (input) => {
    const [time, dateStr] = input.split(':');
    if (!time || !dateStr) {
        throw new Error('Invalid input format. Expected format: "month:1".');
    }
    const date = parseInt(dateStr, 10);
    return { time, date };
};
const transForms = ({ data, week }) => {
    console.log(data, week);
    const sortedData = data
        .map((item) => {
        var _a, _b;
        const labels = ((_b = (_a = item === null || item === void 0 ? void 0 : item.page) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.Labels) || '';
        const { time, date } = convertLabels(labels);
        return {
            time,
            date,
            labels,
            value: date
        };
    })
        .sort((a, b) => a.date - b.date);
    const activeIndex = sortedData.findIndex((item) => item.value >= week);
    return sortedData.map((item, index) => ({
        ...item,
        isActive: index <= activeIndex,
        isToday: index === activeIndex
    }));
};
export const usePregnancyDiary = ({ slug }) => {
    const { $dayjs, i18n } = useContext();
    const { setTitle, setPath } = useAppBar();
    const { user } = useUser();
    const { updateZepUser } = useZepMemory();
    const { update: updatePregnancy } = usePregnancy();
    const { res: page, loading } = useHealthPrograms({
        source: 'notion-content-health-program',
        program: computed(() => slug)
    });
    const pregDateUser = computed(() => { var _a, _b; return (_b = (_a = user.value) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.preg_date; });
    const dueDate = computed(() => {
        const current = $dayjs().startOf('day');
        const week = 41 - $dayjs(pregDateUser.value).diff(current, 'w');
        return week;
    });
    const times = computed(() => transForms({
        data: page.value,
        week: dueDate.value
    }));
    onBeforeMount(() => {
        var _a, _b, _c;
        if (dueDate.value) {
            updatePregnancy({
                preg_read: dueDate.value
            });
            updateZepUser({
                userId: (_a = user.value) === null || _a === void 0 ? void 0 : _a._id,
                first_name: (_b = user.value) === null || _b === void 0 ? void 0 : _b.name,
                metadata: {
                    'user-info': {
                        week: dueDate.value,
                        gender: ((_c = user.value) === null || _c === void 0 ? void 0 : _c.gender) === 'M' ? 'male' : 'female'
                    }
                }
            });
        }
    });
    onMounted(() => {
        setTitle(i18n.t('pregnancy diary'));
        setPath('/membership');
    });
    return {
        loading,
        times,
        dueDate,
        page
    };
};
