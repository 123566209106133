export const useSlug = () => {
    const stringToSlug = (str) => {
        str = str.toLowerCase();
        str = str.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
        str = str.replace(/[^a-z0-9\s-]/g, '');
        str = str.replace(/\s+/g, '-').replace(/-+/g, '-');
        str = str.replace(/^-+|-+$/g, '');
        return str;
    };
    return {
        stringToSlug
    };
};
