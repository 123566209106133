import { defineComponent } from '@nuxtjs/composition-api';
import { StatusBar } from '@capacitor/status-bar';
import useDeepLink from '../composables/use-deeplink';
import useApp from '../composables/use-app';
export default defineComponent({
    setup() {
        useDeepLink();
        const { padding } = useApp();
        StatusBar.setBackgroundColor({
            color: '#ffffff00'
        });
        StatusBar.setOverlaysWebView({ overlay: true });
        return { padding };
    }
});
