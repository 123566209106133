import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
export default function useUtilityHooks() {
    const encodeToBase64 = (data) => {
        const string = JSON.stringify(data);
        const endCoder = new TextEncoder();
        const stringEncode = endCoder.encode(string);
        const stringBinary = String.fromCharCode.apply(null, stringEncode);
        return window.btoa(stringBinary);
    };
    const convertToSeconds = (timeString) => {
        const timeParts = timeString.split(':');
        let totalSeconds = 0;
        if (timeParts.length === 3) {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            totalSeconds = dayjs.duration({ hours, minutes, seconds }).asSeconds();
        }
        else if (timeParts.length === 2) {
            const [minutes, seconds] = timeString.split(':').map(Number);
            totalSeconds = dayjs.duration({ minutes, seconds }).asSeconds();
        }
        return totalSeconds;
    };
    const removeHeadingTags = (input) => {
        const regex = /<h1\b[^>]*>(.*?)<\/h1>/gi;
        return input.replace(regex, '');
    };
    const removeHeadingAndTableTags = (input) => {
        const headingRegex = /<h1\b[^>]*>(.*?)<\/h1>/gi;
        const tableRegex = /<table\b[^>]*>(.*?)<\/table>/gi;
        let result = input.replace(headingRegex, ''); // Remove <h1> tags
        result = result.replace(tableRegex, ''); // Remove <table> tags
        return result;
    };
    const extractTimeDataFromHTML = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const rows = doc.querySelectorAll('table tr');
        const timeData = [];
        const uniqueTimeSet = new Set();
        for (let i = 1; i < rows.length; i++) {
            const row = rows[i];
            const cells = row.querySelectorAll('td');
            const time = cells[0].textContent.trim();
            const description = cells[1].textContent.trim();
            if (!uniqueTimeSet.has(time)) {
                uniqueTimeSet.add(time);
                timeData.push({
                    time,
                    description
                });
            }
        }
        return timeData;
    };
    return {
        encodeToBase64,
        convertToSeconds,
        removeHeadingTags,
        removeHeadingAndTableTags,
        extractTimeDataFromHTML
    };
}
