/* eslint-disable @typescript-eslint/no-unused-vars */
import { formatNumber, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { computed, useContext } from '@nuxtjs/composition-api';
import examples from 'libphonenumber-js/examples.mobile.json';
export const usePhoneNumber = (phone, country) => {
    const { i18n } = useContext();
    const standardPhone = computed(() => {
        return formatPhoneNumber(phone.value, country.value.dial_code);
    });
    const isValid = computed(() => {
        try {
            const formatedPhone = formatPhoneNumber(phone.value, country.value.dial_code);
            return isValidPhone(formatedPhone) || i18n.t('invalid phone').toString();
        }
        catch (error) {
            return false;
        }
    });
    const getExamplePhone = (countryCode) => {
        try {
            return (getExampleNumber(countryCode, examples).formatNational() || '');
        }
        catch (error) { }
    };
    const isValidPhone = (phone) => {
        try {
            const phoneParsed = parsePhoneNumberFromString('+' + phone);
            return phoneParsed.isValid();
        }
        catch (error) {
            return false;
        }
    };
    const formatPhoneNumber = (phone, countryCode) => {
        if (!phone)
            return null;
        phone = phone.toString().replace(/[^\d+]/g, '');
        if (phone.substring(0, countryCode.length) !== countryCode)
            phone = countryCode + phone;
        const phoneFormatted = formatNumber(phone, 'International').replace(/ /g, '');
        return String(Number(phoneFormatted));
    };
    return { standardPhone, isValid, getExamplePhone };
};
