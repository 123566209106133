import { reactive, useRoute } from '@nuxtjs/composition-api';
export default function useAskDoctor() {
    const route = useRoute();
    const queryEduHub = reactive({
        query: {
            bool: {
                must: [],
                filter: [
                    {
                        match_phrase: {
                            'page.properties.EduHub.id': `${route.value.params.id}`
                        }
                    }
                ],
                should: [],
                must_not: []
            }
        }
    });
    return { queryEduHub };
}
