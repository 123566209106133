import { render, staticRenderFns } from "./meeting-room.vue?vue&type=template&id=32c24ff1"
import script from "./meeting-room.vue?vue&type=script&lang=ts"
export * from "./meeting-room.vue?vue&type=script&lang=ts"
import style0 from "./meeting-room.vue?vue&type=style&index=0&id=32c24ff1&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPermissionsProvider: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/permissions-provider.vue').default,WConnection: require('/usr/src/app/src/components/w/connection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VMain})
