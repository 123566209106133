import { computed, defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        isLeft: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        visibility: {
            type: Boolean,
            default: false
        },
        safeArea: {
            type: Object,
            default: () => ({ top: 0 })
        },
        avatar: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const defaultAttrs = {
            app: true,
            'elevate-on-scroll': false,
            flat: true
        };
        const avatarUrl = computed(() => { var _a, _b, _c; return ((_a = props.avatar) === null || _a === void 0 ? void 0 : _a.avatar) ? (_c = (_b = props.avatar) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.url : ''; });
        return { defaultAttrs, avatarUrl };
    }
});
