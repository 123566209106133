import { ref, computed } from '@nuxtjs/composition-api';
/**
 * An enum representing the type of upgrade available.
 */
export var UpgradeType;
(function (UpgradeType) {
    UpgradeType["None"] = "";
    UpgradeType["Patch"] = "patch";
    UpgradeType["Minor"] = "minor";
    UpgradeType["Major"] = "major";
})(UpgradeType || (UpgradeType = {}));
/**
 * A composable function for comparing two semver versions and determining the type of upgrade available.
 * @param currentVersion - The current semver version as a string, a ref of a string, or a computed ref of a string.
 * @param availableVersion - The available semver version as a string, a ref of a string, or a computed ref of a string.
 * @returns An object with the type of upgrade available (patch, minor, major, or none).
 * @author ChatGPT
 */
export function useSemverCompare(currentVersion, availableVersion) {
    const currentVersionRef = ref('');
    const availableVersionRef = ref('');
    // Convert inputs to ref objects if necessary and remove any build number
    if (typeof currentVersion === 'string') {
        currentVersionRef.value = currentVersion.split('+')[0];
    }
    else {
        currentVersionRef.value = currentVersion.value.split('+')[0];
        if ('value' in currentVersion) {
            currentVersion = computed(() => currentVersion.value.split('+')[0]);
        }
    }
    if (typeof availableVersion === 'string') {
        availableVersionRef.value = availableVersion.split('+')[0];
    }
    else {
        availableVersionRef.value = availableVersion.value.split('+')[0];
        if ('value' in availableVersion) {
            availableVersion = computed(() => availableVersion.value.split('+')[0]);
        }
    }
    const upgradeType = computed(() => {
        if (!currentVersionRef.value || !availableVersionRef.value) {
            return UpgradeType.None;
        }
        const [currentMajor, currentMinor, currentPatch] = currentVersionRef.value
            .split('.')
            .map((version) => parseInt(version));
        const [availableMajor, availableMinor, availablePatch] = availableVersionRef.value.split('.').map((version) => parseInt(version));
        if (availableMajor > currentMajor) {
            return UpgradeType.Major;
        }
        if (availableMinor > currentMinor) {
            return UpgradeType.Minor;
        }
        if (availablePatch > currentPatch) {
            return UpgradeType.Patch;
        }
        return UpgradeType.None;
    });
    return {
        upgradeType
    };
}
