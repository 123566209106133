/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { usePreferences } from '~/composables/use-preferences';
export default defineNuxtPlugin(({ app }) => {
    const { set } = usePreferences();
    app.i18n.onBeforeLanguageSwitch = (_, newLocale, __) => {
        set({ key: 'locale', value: newLocale });
        app.$dayjs.locale(newLocale);
    };
});
