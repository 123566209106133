import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useAuth } from '@wellcare/nuxt-module-account/repositories';
import { SUPPORT_CONTACTS } from '../../constants';
export default defineComponent({
    props: {
        miniMode: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const { i18n } = useContext();
        const { user } = useAuth();
        const currentLocale = computed(() => i18n.locale);
        const supports = [
            {
                name: 'Zalo',
                link: SUPPORT_CONTACTS.ZALO,
                icon: require('../../assets/img/logo/icons8-zalo.svg')
            },
            {
                name: 'Viber',
                link: SUPPORT_CONTACTS.VIBER,
                icon: require('../../assets/img/logo/icons8-viber.svg')
            },
            {
                name: 'Whatsapp',
                link: SUPPORT_CONTACTS.WHATSAPP,
                icon: require('../../assets/img/logo/icons8-whatsapp.svg')
            },
            {
                name: 'Email',
                link: 'mailto:' + SUPPORT_CONTACTS.MAIL,
                icon: require('../../assets/img/logo/icons8-email-48.png')
            }
        ];
        const toggleLanguage = () => {
            i18n.setLocale(currentLocale.value === 'vi' ? 'en' : 'vi');
            i18n.setLocaleCookie(currentLocale.value === 'vi' ? 'en' : 'vi');
        };
        const onSupportAction = (link) => {
            window.open(link, '_blank');
        };
        return {
            user,
            currentLocale,
            toggleLanguage,
            supports,
            onSupportAction
        };
    }
});
