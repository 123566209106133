import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import { StatusBar } from '@capacitor/status-bar';
import { useLog } from '@wellcare/vue-component';
import useDeepLink from '../composables/use-deeplink';
import useApp from '~/composables/use-app';
export default defineComponent({
    setup() {
        useDeepLink();
        const { padding } = useApp();
        const { log } = useLog();
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px'
        }));
        const { $vuetify } = useContext();
        try {
            StatusBar.setBackgroundColor({
                color: $vuetify.theme.themes.light.primary.toString()
            });
            StatusBar.setOverlaysWebView({ overlay: true });
        }
        catch (e) {
            log({
                message: 'StatusBar.setOverlaysWebView false',
                context: 'ChatLayout',
                data: e
            });
        }
        return { layoutStyle, padding };
    }
});
