import { useWellcareApi, useRepository } from '@wellcare/nuxt-module-data-layer';
// import { useContext } from '@nuxtjs/composition-api'
import { redeem } from '../../url';
import { AXIOS_TIMEOUT } from '~/repositories/config';
export function useRefCode() {
    // const { $toast, i18n } = useContext()
    const { post } = useWellcareApi();
    const { loading, execute, onSucess, onError, response } = useRepository({
        fetcher({ code, user }) {
            return post({
                timeout: AXIOS_TIMEOUT,
                url: redeem(),
                data: {
                    code,
                    user
                }
            });
        },
        useFetch: false,
        toastOnError: false
    });
    // onError((err) => {
    //   $toast.global.appError({
    //     message: i18n.t(err?.response?.data?.message || err.message)
    //   })
    // })
    return {
        loading,
        execute,
        onSucess,
        onError,
        response
    };
}
