export const useTransform = () => {
    const blockstring = (item) => {
        try {
            item.page.blocks = Object.values(JSON.parse(JSON.parse(item.blockstring)));
            delete item.blockstring;
        }
        catch (e) {
            item.page.blocks = [];
        }
        return item;
    };
    const hit = (value) => {
        const initial = {
            page: { properties: {}, cover: {}, blocks: [] }
        };
        const item = blockstring(value);
        return {
            ...initial,
            ...item
        };
    };
    const hits = (values) => {
        return values.map((value) => hit(value));
    };
    return { blockstring, hit, hits };
};
