import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { computed } from '@nuxtjs/composition-api';
import { createConsultationEndpoint, updateConsultationSessionEndpoint, endConsultationEndpoint, remindCallbackEndpoint, getLatestSessionEndpoint, getConsultationBySessionIdEndpoint, updateConsultationEndpoint } from '../../url';
export const useConsultationSession = () => {
    const { get, post, put } = useWellcareApi();
    const { execute: getLatestSession, loading: loadingGetLatestSession } = useRepository({
        fetcher: (data) => {
            const { user, conversation } = data;
            if (user && conversation) {
                return get({
                    url: getLatestSessionEndpoint(user, conversation)
                });
            }
        },
        useFetch: false
    });
    const { execute: getPatientConsultationBySessionId, loading: loadingGetConsultationBySession } = useRepository({
        fetcher: (data) => {
            const { session, user } = data;
            if (session && user) {
                return get({
                    url: getConsultationBySessionIdEndpoint(),
                    params: {
                        filter: {
                            session: `${session}`,
                            state: { $in: ['WAITING', 'INCONSULTATION'] },
                            user
                        }
                    }
                });
            }
        },
        useFetch: false
    });
    const { execute: getProviderConsultationBySessionId, loading: loadingGetProviderConsultationBySession } = useRepository({
        fetcher: (data) => {
            const { session, providerUser } = data;
            if (session && providerUser) {
                return get({
                    url: getConsultationBySessionIdEndpoint(),
                    params: {
                        filter: {
                            session: `${session}`,
                            state: { $in: ['WAITING', 'INCONSULTATION'] },
                            providerUser: `${providerUser}`
                        }
                    }
                });
            }
        },
        useFetch: false
    });
    const { execute: createConsultationSession, loading: loadingCreateConsultationSession } = useRepository({
        fetcher: (data) => {
            const { chiefComplaint, conversation, patient, provider, providerUser, questions, user, wallet } = data;
            console.log('🚀 ~ useConsultationSession ~ data:', data);
            if (chiefComplaint &&
                conversation &&
                patient &&
                provider &&
                providerUser &&
                questions &&
                user &&
                wallet) {
                return post({
                    url: createConsultationEndpoint(),
                    data
                });
            }
        },
        useFetch: false
    });
    const { execute: updateConsultation, loading: loadingUpdateConsultation } = useRepository({
        fetcher: (data) => {
            const { consultation, ...rest } = data;
            if (consultation) {
                return put({
                    url: updateConsultationEndpoint(consultation),
                    data: rest
                });
            }
        },
        useFetch: false
    });
    const { execute: updateConsultationSession, loading: loadingUpdateConsultationSession } = useRepository({
        fetcher: (data) => {
            const { user, session, payload } = data;
            if (user && Object.keys(payload)) {
                return put({
                    url: updateConsultationSessionEndpoint(session),
                    data: {
                        user,
                        payload
                    }
                });
            }
        },
        useFetch: false
    });
    const { execute: endConsultationSession, loading: loadingEndConsultationSession } = useRepository({
        fetcher: (data) => {
            const { consultation, ...rest } = data;
            if (consultation) {
                return put({
                    url: endConsultationEndpoint(consultation),
                    data: rest
                });
            }
        },
        useFetch: false
    });
    const { execute: remindCallback, loading: loadingRemindCallback } = useRepository({
        fetcher: (data) => {
            const { consultation, time } = data;
            if (consultation) {
                return put({
                    url: remindCallbackEndpoint(consultation),
                    data: {
                        time
                    }
                });
            }
        },
        useFetch: false
    });
    const loading = computed(() => [
        loadingCreateConsultationSession.value,
        loadingEndConsultationSession.value,
        loadingGetConsultationBySession.value,
        loadingGetLatestSession.value,
        loadingGetProviderConsultationBySession.value,
        loadingRemindCallback.value,
        loadingUpdateConsultation.value,
        loadingUpdateConsultationSession.value
    ].includes(true));
    return {
        getLatestSession,
        getPatientConsultationBySessionId,
        getProviderConsultationBySessionId,
        createConsultationSession,
        updateConsultation,
        updateConsultationSession,
        endConsultationSession,
        remindCallback,
        loading
    };
};
