import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    height: 0,
    cover: '',
    title: '',
    subtitle: '',
    iconBack: '',
    path: ''
});
const getters = {
    getField
};
const mutations = {
    updateField,
    SET_HEIGHT(state, val) {
        state.height = val;
    },
    SET_COVER(state, val) {
        state.cover = val;
    },
    SET_SUBTITLE(state, val) {
        state.subtitle = val;
    },
    SET_ICON_BACK(state, val) {
        state.iconBack = val;
    },
    SET_TITLE(state, val) {
        state.title = val;
    },
    SET_PATH(state, val) {
        state.path = val;
    }
};
const actions = {};
export default {
    namespaced: true,
    strict: false,
    state,
    getters,
    mutations,
    actions
};
