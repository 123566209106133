import { defineComponent, computed, ref, provide, useRouter } from '@nuxtjs/composition-api';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useAppBar } from '../composables/use-app-bar';
import WAppBar from '../components/app-bar.vue';
import useApp from '../composables/use-app';
import useDeepLink from '../composables/use-deeplink';
import RecommendMembership from '../components/membership/recommend.vue';
export default defineComponent({
    components: { WAppBar, RecommendMembership },
    setup() {
        useDeepLink();
        const container = ref(null);
        provide('layout-container', container);
        const { title, visibility, path, setPath } = useAppBar();
        const { padding } = useApp();
        const router = useRouter();
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 48 + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px'
        }));
        try {
            StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
        }
        catch (e) { }
        const onGoback = () => {
            const pathGoback = path.value;
            if (pathGoback) {
                setPath('');
                router.push(pathGoback);
            }
            else {
                router.back();
            }
        };
        return { title, visibility, layoutStyle, padding, path, onGoback };
    }
});
