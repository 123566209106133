import { defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api';
import { useDebounceFn } from '@vueuse/core';
import { useValidateRefCode } from '../../../repositories/membership/ref-code/validate-ref-code';
import { useRefCode } from '../../../repositories/membership/ref-code/use-ref-code';
export default defineComponent({
    props: {
        showDetail: {
            type: Boolean,
            default: false
        },
        userInfor: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, { emit }) {
        var _a, _b, _c;
        const enteredCode = ref();
        const { $toast, i18n } = useContext();
        const isBtnDisabled = ref(true);
        const userRef = ref({
            _id: ((_a = props.userInfor) === null || _a === void 0 ? void 0 : _a.id) || '',
            phone: ((_b = props.userInfor) === null || _b === void 0 ? void 0 : _b.phone) || '',
            name: ((_c = props.userInfor) === null || _c === void 0 ? void 0 : _c.name) || ''
        });
        const { execute: executeValidateRefCode, onSucess: onSuccessValidateRefCode } = useValidateRefCode();
        const { execute: executeRefCode, onSucess: onSuccessRefCode } = useRefCode();
        const handleApplyCode = () => {
            executeRefCode({ code: enteredCode.value, user: userRef.value });
        };
        const debouncedFn = useDebounceFn(() => {
            executeValidateRefCode({
                code: enteredCode.value,
                user: userRef.value
            });
        }, 400);
        watch(() => enteredCode.value, () => {
            debouncedFn();
        });
        onSuccessValidateRefCode((res) => {
            if (res.code === 200) {
                isBtnDisabled.value = false;
            }
            else {
                isBtnDisabled.value = true;
            }
        });
        onSuccessRefCode((res) => {
            if (res.code === 200) {
                emit('on-close');
                enteredCode.value = '';
                $toast.global.appSuccess({
                    message: i18n.t('Your request has been submitted. Our care team should contact you within the next hours via Zalo +84366905905 for the next step.')
                });
            }
        });
        const inheritAttrs = ref(false);
        return {
            enteredCode,
            handleApplyCode,
            userRef,
            isBtnDisabled,
            inheritAttrs
        };
    }
});
