import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { usePreferences } from '@wellcare/vue-component';
export default defineNuxtMiddleware(async ({ route }) => {
    const { set } = usePreferences();
    const { path } = route;
    if (!path.includes('/auth')) {
        const redirectStep = btoa(location.href);
        await set({ key: 'redirectStep', value: redirectStep });
    }
});
