import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    show: true,
    title: '',
    subTitle: '',
    backgroundColor: 'white',
    avatar: {},
    path: ''
});
const getters = {
    getField
};
const mutations = {
    updateField
};
const actions = {};
export default {
    namespaced: true,
    strict: false,
    state,
    getters,
    mutations,
    actions
};
