import { ref } from '@nuxtjs/composition-api';
export const useRefreshKey = () => {
    const key = ref(0);
    const refresh = () => {
        key.value = Date.now();
    };
    return {
        key,
        refresh
    };
};
