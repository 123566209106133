import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';
export default defineComponent({
    setup() {
        const router = useRouter();
        const shouldOpen = ref(false);
        const open = () => (shouldOpen.value = true);
        const close = () => (shouldOpen.value = false);
        const question = ref('');
        const onAskAI = () => {
            const q = question.value;
            if (q.trim() === '')
                return;
            question.value = '';
            router.push({
                path: '/edu-hub/q-a',
                query: {
                    message: q
                }
            });
        };
        return { open, close, shouldOpen, onAskAI, question };
    }
});
