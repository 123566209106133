import { defineComponent, computed, ref, provide } from '@nuxtjs/composition-api';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useAppBar } from '../composables/use-app-bar';
import WAppBar from '../components/app-bar.vue';
import useApp from '../composables/use-app';
import useDeepLink from '../composables/use-deeplink';
export default defineComponent({
    components: { WAppBar },
    setup() {
        useDeepLink();
        const container = ref(null);
        provide('layout-container', container);
        const { title, visibility, avatar } = useAppBar();
        const { padding } = useApp();
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 48 + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px'
        }));
        try {
            StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
        }
        catch (e) { }
        // console.log(avatar)
        return { title, visibility, layoutStyle, padding, avatar };
    }
});
