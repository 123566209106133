import { computed, watch } from '@nuxtjs/composition-api';
import { useElasticSearch } from '@wellcare/nuxt-module-notion/repositories';
import { useTransform } from '~/composables';
export * from './baby-development';
export * from './pregnancy-diary';
export const useHealthPrograms = ({ source, program }) => {
    const { hits: transform } = useTransform();
    const searchProgram = computed(() => {
        return {
            size: 100,
            from: 0,
            query: {
                bool: {
                    must: [],
                    filter: [
                        {
                            match_phrase: {
                                'page.properties.Program.properties.Key': program.value
                            }
                        }
                    ],
                    should: [],
                    must_not: []
                }
            }
        };
    });
    const { execute, hits, loading } = useElasticSearch(source, searchProgram);
    watch(program, (val) => {
        if (val) {
            execute();
        }
    }, {
        immediate: true
    });
    const res = computed(() => {
        var _a;
        return ((_a = hits.value) === null || _a === void 0 ? void 0 : _a.length) ? transform(hits.value) : [];
    });
    return {
        searchProgram,
        execute,
        loading,
        res
    };
};
/**
 * Function that defines a hook to fetch health programs based on a given source and program.
 * It uses the ElasticSearch API and a transformation function from 'useTransform'.
 * The hook watches for changes in the program and executes the search accordingly.
 * Returns the search program, execute function, loading state, and transformed results.
 */
