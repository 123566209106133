import { computed } from '@nuxtjs/composition-api';
export default function useShortName(user) {
    // const { i18n } = useContext()
    var _a, _b, _c, _d;
    // const words = (user.value.name as string).split(' ')
    const roles = [];
    const conditions = {
        isProvider: ((_a = user.value) === null || _a === void 0 ? void 0 : _a.isProvider) && ((_b = user.value) === null || _b === void 0 ? void 0 : _b.provider)
            ? user.value.provider.title
            : '',
        isAdmin: ((_c = user.value) === null || _c === void 0 ? void 0 : _c.isAdmin) ? '' : '',
        isTest: ((_d = user.value) === null || _d === void 0 ? void 0 : _d.isTest) ? 'test' : ''
        // isPremium: user.value.isPremium ? ' (Premium)' : ''
    };
    if (conditions === null || conditions === void 0 ? void 0 : conditions.isTest) {
        roles.push(conditions === null || conditions === void 0 ? void 0 : conditions.isTest);
    }
    if (conditions === null || conditions === void 0 ? void 0 : conditions.isAdmin) {
        roles.push(conditions === null || conditions === void 0 ? void 0 : conditions.isAdmin);
    }
    const name = computed(() => {
        var _a;
        try {
            const words = ((_a = user.value) === null || _a === void 0 ? void 0 : _a.name).split(' ');
            return `${conditions === null || conditions === void 0 ? void 0 : conditions.isProvider}${words[words.length - 1]}`;
        }
        catch (_b) {
            return '';
        }
    });
    return {
        name,
        isProvider: conditions === null || conditions === void 0 ? void 0 : conditions.isProvider,
        isAdmin: conditions === null || conditions === void 0 ? void 0 : conditions.isAdmin,
        isTest: conditions === null || conditions === void 0 ? void 0 : conditions.isTest
    };
}
