import { Device } from '@capacitor/device';
import { useContext, useStore } from '@nuxtjs/composition-api';
import { CapacitorPluginPermissions } from '@wellcare/capacitor-permissions';
import { createUserDeviceUrl, updatePermissionDeviceUrl } from './url';
export function useDeviceTracking() {
    const { $axios, $toast } = useContext();
    const { commit } = useStore();
    const addDevice = async () => {
        try {
            const deviceId = await Device.getId();
            const deviceInfo = await Device.getInfo();
            const permissions = {
                camera: 'unknown',
                microphone: 'unknown',
                notifications: 'unknown'
            };
            for (const key of Object.keys(permissions)) {
                const res = await CapacitorPluginPermissions.checkPermission({
                    permission: key
                });
                console.log(`[wellcare - debug] permission ${key}: ${res[key]}`);
                permissions[key] = res[key];
            }
            commit('updateField', {
                path: 'permissions',
                value: permissions
            });
            console.log('[wellcare - debug] set permissions state ' +
                JSON.stringify(permissions));
            await $axios.post(createUserDeviceUrl, {
                info: deviceInfo,
                deviceId: deviceId.identifier,
                permissions
            });
        }
        catch (error) {
            $toast.global.appError({
                message: error.message
            });
        }
    };
    const updatePermission = async (payload) => {
        try {
            const deviceId = await Device.getId();
            await $axios.put(updatePermissionDeviceUrl(deviceId.identifier), payload);
        }
        catch (error) {
            $toast.global.appError({
                message: error.message
            });
        }
    };
    return {
        addDevice,
        updatePermission
    };
}
