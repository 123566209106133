import { render, staticRenderFns } from "./error.vue?vue&type=template&id=3e881cfb"
import script from "./error.vue?vue&type=script&lang=ts"
export * from "./error.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WCardError: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/card/error.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VContainer})
