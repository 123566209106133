import { computed, defineComponent, ref, useContext, useRouter, useStore, watch } from '@nuxtjs/composition-api';
import { useAuth } from '@wellcare/nuxt-module-account/repositories';
import { usePreferences } from '../../composables/use-preferences';
import ActivateMembershipCode from './corporate/activate-membership-code.vue';
export default defineComponent({
    name: 'RecommendMembership',
    components: { ActivateMembershipCode },
    setup() {
        var _a;
        const { set, get } = usePreferences();
        const { $dayjs, i18n, $vuetify } = useContext();
        const { user } = useAuth();
        const { state } = useStore();
        const router = useRouter();
        const dialog = ref(false);
        const forceOpen = computed(() => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.membership) === null || _a === void 0 ? void 0 : _a.isPopupMember; });
        const safeArea = computed(() => { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.safeArea) || { top: 20 }; });
        const userInfor = (_a = state === null || state === void 0 ? void 0 : state.authen) === null || _a === void 0 ? void 0 : _a.user;
        const isMember = computed(() => { var _a; return Boolean('isMember' in user.value ? (_a = user.value) === null || _a === void 0 ? void 0 : _a.isMember : true); });
        const defaultBenefit = [
            {
                content: 'waiver of the service fee for individual consultations'
            },
            {
                content: 'special rates and a prioritised schedule'
            },
            {
                content: 'boundless answers from our HealthGPT'
            },
            {
                content: 'optional upgrade: personal doctor, asynchronous care'
            }
        ];
        const extraBenefit = [
            {
                content: i18n.t('asynchronous telehealth'),
                subcontent: i18n.t("times x knowledge question, follow-up question, or receiving a doctor's endorsement on HealthGPT", { use: 10 })
            },
            {
                content: i18n.t('personal doctor'),
                subcontent: i18n.t('having a personal doctor take care of you includes 90 minutes of messaging, voice call, or video call without booking.', { time: 90 })
            }
        ];
        const loadingClose = ref(false);
        const setTimeView = async () => {
            await set({
                key: 'membershipLastView',
                value: $dayjs().toISOString()
            });
        };
        const onClose = async () => {
            loadingClose.value = true;
            await setTimeView();
            loadingClose.value = false;
            dialog.value = false;
        };
        const handleDirectoCheckoutMembership = async () => {
            await onClose();
            router.push('/checkout/membership');
        };
        const isMobile = computed(() => $vuetify.breakpoint.height <= 820);
        const pageHeight = computed(() => document.body.scrollHeight);
        watch(forceOpen, () => {
            dialog.value = true;
        });
        watch(isMember, async () => {
            if (user.value.phone === '84900000011' || user.value.provider)
                return;
            const currView = await get({ key: 'membershipLastView' });
            if (currView.value) {
                // reccomend user view membership every week
                if ($dayjs().isAfter(currView.value) &&
                    $dayjs().diff(currView.value, 'day') >= 7)
                    dialog.value = !isMember.value;
                // if user has never seen, recommend user
            }
            else
                dialog.value = !isMember.value;
        }, {
            immediate: true
        });
        const handleCloseDialog = () => {
            dialog.value = false;
        };
        return {
            dialog,
            defaultBenefit,
            extraBenefit,
            loadingClose,
            safeArea,
            userInfor,
            isMobile,
            pageHeight,
            onClose,
            handleDirectoCheckoutMembership,
            handleCloseDialog
        };
    }
});
