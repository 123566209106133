import { useStore } from '@nuxtjs/composition-api';
export const useLayoutContent = () => {
    const { commit } = useStore();
    const setTitle = (val) => {
        commit('layout-content/SET_TITLE', val);
    };
    const setPath = (val) => {
        commit('layout-content/SET_PATH', val);
    };
    const setCover = (url) => {
        commit('layout-content/SET_COVER', '');
        commit('layout-content/SET_COVER', url);
        commit('layout-content/SET_HEIGHT', 215);
    };
    return {
        setTitle,
        setCover,
        setPath
    };
};
