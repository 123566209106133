import { onMounted, onUnmounted, ref, useRouter, useContext } from '@nuxtjs/composition-api';
import { App } from '@capacitor/app';
import { useLog } from '@wellcare/vue-component';
const useDeepLink = () => {
    const { $config } = useContext();
    const router = useRouter();
    const { log } = useLog();
    const listener = ref();
    const addListeners = async () => {
        listener.value = await App.addListener('appUrlOpen', (data) => {
            log({
                context: 'useDeepLink',
                message: 'App.addListener("appUrlOpen")',
                data
            });
            const DEEPLINK_SCHEME = $config.deepLinkScheme;
            const url = data === null || data === void 0 ? void 0 : data.url;
            const isOpenedFromDeeplink = url.includes(DEEPLINK_SCHEME);
            if (!isOpenedFromDeeplink) {
                return;
            }
            const pathToPush = url.substring(DEEPLINK_SCHEME.length - 1);
            log({
                context: 'useDeepLink',
                message: 'Receive deeplink',
                data: {
                    raw: data,
                    pathToPush
                }
            });
            router.push(pathToPush);
        });
    };
    onMounted(() => {
        addListeners();
    });
    onUnmounted(() => {
        listener.value.remove();
    });
    return {};
};
export default useDeepLink;
