import { ref, defineComponent, watch } from '@nuxtjs/composition-api';
import { useNetwork } from '@vueuse/core';
import useApp from '~/composables/use-app';
export default defineComponent({
    setup() {
        const { isOnline } = useNetwork();
        const shouldShow = ref(false);
        const { padding } = useApp();
        watch(isOnline, () => {
            if (!isOnline.value) {
                shouldShow.value = true;
            }
            else {
                shouldShow.value = false;
            }
        }, { immediate: true });
        return { shouldShow, isOnline, padding };
    }
});
