import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import useApp from '../composables/use-app';
import useDeepLink from '../composables/use-deeplink';
import { useAuthHandle } from '../repositories/use-auth-handle';
export default defineComponent({
    setup() {
        const ctx = useContext();
        const { executeSignOut } = useAuthHandle();
        useDeepLink();
        const { padding } = useApp();
        const shouldRenderAppbar = computed(() => !Capacitor.isNativePlatform() && ctx.$vuetify.breakpoint.mobile);
        try {
            StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
        }
        catch (e) { }
        // onMounted(() => {
        //   drawer.value = $vuetify.breakpoint.lgAndUp
        // })
        const logout = () => {
            executeSignOut();
        };
        return {
            padding,
            logout,
            shouldRenderAppbar
        };
    }
});
