import { StatusBar } from '@capacitor/status-bar';
import { computed, defineComponent, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { useAuth } from '@wellcare/nuxt-module-account/repositories';
import Recommend from '../components/membership/recommend.vue';
import useApp from '../composables/use-app';
import useDeepLink from '../composables/use-deeplink';
export default defineComponent({
    components: {
        Recommend
    },
    setup() {
        useDeepLink();
        const { user } = useAuth();
        const { $vuetify, i18n } = useContext();
        const { padding } = useApp();
        const bottomMenu = ref(null);
        const items = computed(() => {
            const menu = [
                {
                    key: 'home',
                    text: i18n.t('home').toString(),
                    icon: '$homeActive',
                    activeIcon: '$homeActive',
                    to: '/'
                },
                {
                    key: 'familyHealth',
                    text: i18n.t('family health').toString(),
                    icon: '$medicalKit',
                    activeIcon: '$medicalKit',
                    to: '/family-health'
                },
                {
                    key: 'membership',
                    text: i18n.t('membership').toString(),
                    icon: '$membership',
                    activeIcon: '$membership',
                    to: '/membership'
                }
                // temporary disable edu-hub page
                // {
                //   text: 'EduHub',
                //   icon: '$bulb',
                //   activeIcon: '$bulb',
                //   to: '/edu-hub'
                // }
            ];
            return menu.filter((item) => {
                if (user.value.phone === '84900000011')
                    return item.key !== 'membership';
                return item;
            });
        });
        StatusBar.setBackgroundColor({
            color: $vuetify.theme.themes.light.primary.toString()
        });
        StatusBar.setOverlaysWebView({ overlay: true });
        const route = useRoute();
        const activeRoute = ref('');
        watch(() => route.value.path, () => {
            activeRoute.value = route.value.path;
        }, { immediate: true });
        return { items, bottomMenu, activeRoute, padding };
    }
});
