import { defineComponent } from '@nuxtjs/composition-api';
import useDeepLink from '../composables/use-deeplink';
import useApp from '../composables/use-app';
export default defineComponent({
    setup() {
        useDeepLink();
        const { padding } = useApp();
        return { padding };
    }
});
