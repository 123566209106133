import { KeepAwake } from '@capacitor-community/keep-awake';
const useKeepAwake = () => {
    const keepAwake = async () => {
        await KeepAwake.keepAwake();
    };
    const allowSleep = async () => {
        await KeepAwake.allowSleep();
    };
    const isSupported = async () => {
        const result = await KeepAwake.isSupported();
        return result.isSupported;
    };
    return { keepAwake, allowSleep, isSupported };
};
export default useKeepAwake;
