var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.visibility),expression:"visibility"}],staticClass:"app-bar",staticStyle:{"z-index":"15","transition":"0.2s linear","overflow":"hidden"},style:({
    height: ((_vm.safeArea.top + 56) + "px !important"),
    'background-color': _vm.backgroundColor || 'white'
  })},'v-app-bar',Object.assign({}, _vm.defaultAttrs, _vm.$attrs),false),[_c('v-row',{attrs:{"align":"end","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","position":"relative"}},[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:back')}}},[_c('v-icon',{attrs:{"size":28}},[_vm._v("$chevronLeft")])],1),_vm._v(" "),_c('div',{style:(_vm.isLeft
                ? {
                    flex: 1,
                    textAlign: 'left'
                  }
                : {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  })},[_c('div',{staticClass:"d-flex justify-center align-center flex-column",staticStyle:{"width":"100%"}},[_c('h3',{staticStyle:{"width":"100%","font-size":"17px"}},[_vm._v("\n                "+_vm._s(_vm.$t(_vm.title))+"\n              ")]),_vm._v(" "),_vm._t("subtitle")],2)]),_vm._v(" "),_vm._t("actions")],2),_vm._v(" "),_c('v-scale-transition',[(_vm.avatar && _vm.avatarUrl)?_c('div',{staticClass:"d-flex justify-end align-center"},[_c('w-avatar',{key:_vm.avatarUrl,staticStyle:{"border":"1px solid yellow"},attrs:{"name":_vm.avatar.name,"user-id":_vm.avatar.userId,"size":"35","src":_vm.avatarUrl}})],1):_vm._e()])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }