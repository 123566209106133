import { defineComponent, computed, ref, provide, useStore } from '@nuxtjs/composition-api';
import { StatusBar, Style } from '@capacitor/status-bar';
import { extractSubstring } from '@wellcare/nuxt-module-chat/compositions/extract';
import joinRoom from '@wellcare/nuxt-module-chat/compositions/join-room';
import WAppBar from '../components/app-bar.vue';
import RecommendMembership from '../components/membership/recommend.vue';
import useApp from '../composables/use-app';
import useDeepLink from '../composables/use-deeplink';
import { useAppBar } from '../composables/use-app-bar';
export default defineComponent({
    components: { WAppBar, RecommendMembership },
    setup() {
        useDeepLink();
        const { padding } = useApp();
        const joinAction = joinRoom();
        const { state } = useStore();
        const user = computed(() => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.authen) === null || _a === void 0 ? void 0 : _a.user; });
        const { title, visibility, path, labels } = useAppBar();
        const container = ref(null);
        provide('layout-container', container);
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 48 + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px'
        }));
        try {
            StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
        }
        catch (e) {
            console.error(e);
        }
        const onClick = async () => {
            var _a;
            await joinAction.joinGptRoom((_a = user.value) === null || _a === void 0 ? void 0 : _a._id, extractSubstring(labels.value, 'bot').split(':')[1]);
        };
        return { title, visibility, layoutStyle, padding, path, onClick };
    }
});
