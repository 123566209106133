import Home from './home.vue';
import MedicalKit from './medical-kit.vue';
import Membership from './membership.vue';
import Bulb from './bulb.vue';
import MedicalReceipt from './medical-receipt.vue';
import Text from './text.vue';
import AppstoreDownload from './appstore-download.vue';
import InternetToPhone from './internet-to-phone.vue';
export { Home, MedicalKit, Bulb, MedicalReceipt, Text, AppstoreDownload, Membership, InternetToPhone };
export default {};
