import { render, staticRenderFns } from "./blank.vue?vue&type=template&id=e8a570e2"
import script from "./blank.vue?vue&type=script&lang=ts"
export * from "./blank.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPermissionsProvider: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/permissions-provider.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VMain})
