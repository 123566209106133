import { ref } from '@nuxtjs/composition-api';
import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { getPromotionVoucherUrl } from '../url';
export function getPromotionVoucher(keyPolicy = ref('doctor-invitation')) {
    const { get } = useWellcareApi();
    const voucher = ref({});
    const { response, onError, onSucess, loading, execute } = useRepository({
        fetcher: (param) => get({
            url: getPromotionVoucherUrl(param)
        }),
        conditions: keyPolicy,
        useFetch: false
    });
    onSucess((res) => {
        voucher.value = res.results;
    });
    return {
        response,
        onError,
        onSucess,
        loading,
        voucher,
        execute
    };
}
