import { SafeArea } from 'capacitor-plugin-safe-area';
import { Device } from '@capacitor/device';
import { usePreferences } from '~/composables/use-preferences';
const app = async ({ store, i18n }) => {
    try {
        const { get } = usePreferences();
        const locale = (await get({ key: 'locale' })).value;
        i18n.setLocale(locale);
        const device = await Device.getInfo();
        const safeArea = await SafeArea.getSafeAreaInsets().then(({ insets }) => insets);
        const statusBarHeight = await SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => statusBarHeight);
        store.state.app.safeArea = { ...safeArea, ...{ statusBarHeight } };
        store.state.app.device = device;
        const root = document.querySelector(':root');
        root.style.setProperty('--toast-padding-top', `${safeArea.top + 20}px`);
        root.style.setProperty('--lg-toolbar-padding', `${safeArea.top}px`);
    }
    catch (error) {
        console.error('error at middleware: ' + error);
    }
};
export default app;
