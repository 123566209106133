import { computed, useContext } from '@nuxtjs/composition-api';
export const useAppBar = () => {
    const { store } = useContext();
    const title = computed(() => store.state['app-bar'].title);
    const avatar = computed(() => store.state['app-bar'].avatar);
    const labels = computed(() => store.state['app-bar'].labels);
    const visibility = computed(() => store.state['app-bar'].show);
    const subTitle = computed(() => store.state['app-bar'].subTitle);
    const path = computed(() => store.state['app-bar'].path);
    const setTitle = (payload) => {
        store.state['app-bar'].title = payload;
    };
    const setVisibility = (payload) => {
        store.state['app-bar'].show = payload;
    };
    const setAvatar = (payload) => {
        store.state['app-bar'].avatar = payload;
    };
    const setSubtitle = (payload) => {
        store.state['app-bar'].subTitle = payload;
    };
    const setLabels = (payload) => {
        store.state['app-bar'].labels = payload;
    };
    const setPath = (payload) => {
        store.state['app-bar'].path = payload;
    };
    return {
        title,
        path,
        avatar,
        labels,
        subTitle,
        visibility,
        setTitle,
        setLabels,
        setAvatar,
        setSubtitle,
        setVisibility,
        setPath
    };
};
