var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{ref:"el",style:({
    'max-width': '100vw',
    'overflow-x': 'hidden'
  })},[_c('v-app-bar',{staticClass:"appbar white--text",staticStyle:{"z-index":"15","transition":"0.2s linear","overflow":"hidden"},style:({
      height: ((_vm.padding.top + 24 + 56) + "px !important")
    }),attrs:{"fixed":"","flat":"","color":_vm.shouldMinimize ? 'white' : 'transparent',"elevation":_vm.shouldMinimize ? 4 : 0}},[_c('v-row',{attrs:{"align":"end","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticStyle:{"background":"rgba(0, 0, 0, 0.05)"},attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"color":_vm.shouldMinimize ? 'black' : 'white'}},[_vm._v("$chevronLeft")])],1),_vm._v(" "),_c('v-slide-x-reverse-transition',[_c('h3',{staticClass:"ml-2 font-weight-black",style:({
                opacity: _vm.minimizeOpacity,
                color: _vm.shouldMinimize ? 'black' : 'white'
              })},[_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")])])],1)])],1)],1),_vm._v(" "),_c('v-main',[_c('v-row',{class:[
        {
          primary: !_vm.cover
        },
        'px-4 text-uppercase white--text'
      ],style:(Object.assign({}, {'padding-top': _vm.padding.top + 56 + 'px',
        'background-image': ("linear-gradient(360deg, rgba(0,0,0,0.7735688025210083) 0%, rgba(0,0,0,0) 56%), url('" + _vm.cover + "')"),
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center'},
        (_vm.height && {
          height: ((_vm.height + _vm.padding.top) + "px !important")
        })))},[_c('v-col',{staticClass:"d-flex flex-column justify-space-between"},[_c('v-spacer'),_vm._v(" "),_c('h2',{staticClass:"text-uppercase py-4 font-weight-black",style:({
            opacity: 1 - _vm.minimizeOpacity,
            transform: ("scale(" + (1 - _vm.minimizeOpacity) + ")"),
            'text-shadow':
              '2px 7px 5px rgba(0,0,0,0.3),0px -4px 10px rgba(255,255,255,0.3)'
          })},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")])],1)],1),_vm._v(" "),_c('v-container',{staticClass:"px-3",on:{"scroll":_vm.onScroll}},[_c('w-permissions-provider',{attrs:{"safe-area":_vm.padding}},[_c('Nuxt',{attrs:{"keep-alive":""},on:{"scroll":_vm.onScroll}})],1)],1)],1),_vm._v(" "),_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldMinimize),expression:"shouldMinimize"}],attrs:{"color":"primary","fab":"","fixed":"","bottom":"","small":"","right":""},on:{"click":_vm.scrollToTop}},[_c('v-icon',[_vm._v("$chevronUp")])],1)],1),_vm._v(" "),(_vm.shouldShowAskQuestion)?_c('v-btn',{staticClass:"font-weight-bold zoominout-animation",attrs:{"color":"primary","fixed":"","bottom":"","large":"","left":""},on:{"click":function($event){return _vm.askQuestionDialog.open()}}},[_vm._v("\n    "+_vm._s(_vm.$t('ask question'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('w-ask-question-dialog',{ref:"askQuestionDialog"}),_vm._v(" "),_c('w-connection')],1)}
var staticRenderFns = []

export { render, staticRenderFns }