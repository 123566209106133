import { computed, onMounted, useContext } from '@nuxtjs/composition-api';
import { useUser } from '@wellcare/nuxt-module-account/repositories';
import { useAppBar, useHealthPrograms } from '~/composables';
const convertLabels = (input) => {
    const [time, dateStr] = input.split(':');
    if (!time || !dateStr) {
        throw new Error('Invalid input format. Expected format: "month:1".');
    }
    const date = parseInt(dateStr, 10);
    // If time is "year", multiply the date by 12 to convert it to months
    const convertedDate = time === 'year' ? date * 12 : date;
    return { time, date, convertedDate };
};
const timeOrder = { month: 1, year: 2 };
const transformData = (data, dob) => {
    const sortedData = data
        .map((item) => {
        var _a, _b, _c, _d;
        const labels = ((_b = (_a = item === null || item === void 0 ? void 0 : item.page) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.Labels) || '';
        const slug = ((_d = (_c = item === null || item === void 0 ? void 0 : item.page) === null || _c === void 0 ? void 0 : _c.properties) === null || _d === void 0 ? void 0 : _d.Slug) || '';
        const { time, date, convertedDate } = convertLabels(labels);
        return {
            time,
            date,
            labels,
            slug,
            value: convertedDate
        };
    })
        .sort((a, b) => timeOrder[a.time] - timeOrder[b.time] || a.date - b.date);
    const activeIndex = sortedData.findIndex((item) => item.value >= dob);
    return sortedData.map((item, index) => ({
        ...item,
        isActive: index <= activeIndex,
        isToday: index === activeIndex
    }));
};
export const useBabyDevelopment = ({ slug }) => {
    const { i18n, $dayjs } = useContext();
    const { user } = useUser();
    const { setTitle, setPath, setLabels } = useAppBar();
    const { res, loading } = useHealthPrograms({
        source: 'notion-content-health-program',
        program: computed(() => slug)
    });
    const dob = computed(() => {
        var _a;
        const curr = $dayjs().startOf('day');
        const dobMonth = curr.diff((_a = user.value.meta) === null || _a === void 0 ? void 0 : _a.baby_date, 'month');
        return dobMonth;
    });
    const times = computed(() => transformData(res.value, dob.value));
    onMounted(() => {
        setLabels('show_in:health_program,bot:cung_con_khon_lon,participant_id:e47c6fa72aec2aee44c276e8');
        setTitle(i18n.t('baby development'));
        setPath('/membership');
    });
    return {
        dob,
        times,
        loading
    };
};
