import { getField, updateField } from 'vuex-composition-map-fields';
const state = () => ({
    isPopupMember: 0,
    healthProgram: {
        time: '',
        date: '',
        labels: '',
        slug: '',
        value: '',
        milestone: '',
        formState: {}
    }
});
const getters = {
    getField
};
const mutations = {
    updateField,
    TOGGLE_POPUP_MEMBERSHIP(state) {
        state.isPopupMember += 1;
    },
    SET_BABY_TIME_INFO(state, payload) {
        state.healthProgram.time = payload.time;
        state.healthProgram.date = payload.date;
        state.healthProgram.labels = payload.labels;
        state.healthProgram.slug = payload.slug;
        state.healthProgram.value = payload.value;
    },
    SET_CHECKLIST_MILESTONE(state, payload) {
        state.healthProgram.milestone = payload.milestone;
    },
    SET_CHECKLIST_STATE(state, payload) {
        state.healthProgram.formState = payload.formState;
    }
};
const actions = {
    togglePopupMembership({ commit }) {
        commit('TOGGLE_POPUP_MEMBERSHIP');
    },
    setHealthProgramBabyDev({ commit }, payload) {
        commit('SET_HEALTH_PROGRAM_BABY_DEV', payload);
    }
};
export default {
    namespaced: true,
    strict: false,
    state,
    getters,
    mutations,
    actions
};
