const provider = ({ store, $toast, redirect }) => {
    var _a, _b;
    const user = (_a = store.getters.getField('authen')) === null || _a === void 0 ? void 0 : _a.user;
    const isProvider = user.isProvider;
    const isAdmin = user.isAdmin;
    if (!isProvider && !isAdmin) {
        (_b = $toast.global) === null || _b === void 0 ? void 0 : _b.appWarning({ message: 'NO_PERMISSION' });
        redirect('/');
    }
};
export default provider;
