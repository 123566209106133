import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { updateZepUserEndpoint } from '../../url';
export const useZepMemory = () => {
    const { put } = useWellcareApi();
    const { execute: updateZepUser } = useRepository({
        fetcher: (data) => {
            const { userId, ...rest } = data;
            if (userId) {
                return put({
                    url: updateZepUserEndpoint(userId),
                    data: rest
                });
            }
        },
        useFetch: false
    });
    return { updateZepUser };
};
