import { render, staticRenderFns } from "./content-without-question.vue?vue&type=template&id=112a3207&scoped=true"
import script from "./content-without-question.vue?vue&type=script&lang=ts"
export * from "./content-without-question.vue?vue&type=script&lang=ts"
import style0 from "./content-without-question.vue?vue&type=style&index=0&id=112a3207&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112a3207",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPermissionsProvider: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/permissions-provider.vue').default,WAskQuestionDialog: require('/usr/src/app/src/components/w/ask-question-dialog.vue').default,WConnection: require('/usr/src/app/src/components/w/connection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VApp,VAppBar,VBtn,VCol,VContainer,VFabTransition,VIcon,VMain,VRow,VSlideXReverseTransition})
