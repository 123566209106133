import { computed, useStore } from '@nuxtjs/composition-api';
export default () => {
    const { state } = useStore();
    const device = computed(() => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.device; });
    const safeArea = computed(() => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.safeArea; });
    const isIos = computed(() => device.value.operatingSystem === 'ios');
    const padding = computed(() => {
        var _a, _b, _c, _d;
        return ({
            top: ((_a = safeArea.value) === null || _a === void 0 ? void 0 : _a.top) || 0,
            right: ((_b = safeArea.value) === null || _b === void 0 ? void 0 : _b.right) || 0,
            left: ((_c = safeArea.value) === null || _c === void 0 ? void 0 : _c.left) || 0,
            bottom: ((_d = safeArea.value) === null || _d === void 0 ? void 0 : _d.bottom) || 0
        });
    });
    return { device, safeArea, padding };
};
