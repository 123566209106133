import Vue from 'vue';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';
import { usePreferences } from '~/composables';
// // REF: https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/analytics/README.md
// // DEBUG: https://firebase.google.com/docs/analytics/debugview
export default defineNuxtPlugin(async ({ app }, inject) => {
    const { router, head } = app;
    const { get } = usePreferences();
    const PLATFORM = Capacitor.getPlatform();
    if (PLATFORM === 'ios' || PLATFORM === 'android') {
        const setCurrentScreen = async (to) => {
            // eslint-disable-next-line
            const title = head['meta'].find((m) => m.name === 'title' || m.name === 'og:title').content;
            console.log(`gtm.screenName:${title || to.name}`);
            console.log(`gtm.screenClassOverride:${to.fullPath}`);
            try {
                await FirebaseAnalytics.setCurrentScreen({
                    screenName: title || to.name,
                    screenClassOverride: to.path
                });
            }
            catch (e) {
                console.error(`gtm.setCurrentScreen:${e.message}`);
            }
        };
        const setUserId = async () => {
            try {
                const pref = await get({ key: 'user' });
                const user = (pref === null || pref === void 0 ? void 0 : pref.value)
                    ? JSON.parse(pref.value)
                    : null;
                console.log(`gtm.setUserId:${user._id}`);
                await FirebaseAnalytics.setUserId({ userId: user._id });
                await Promise.any([
                    FirebaseAnalytics.setUserProperty({
                        key: 'gender',
                        value: user.gender
                    }),
                    FirebaseAnalytics.setUserProperty({ key: 'dob', value: user.dob })
                ]);
            }
            catch (e) {
                console.error(`gtm.setUserId:${e.message}`);
            }
        };
        const setUserLocale = async () => {
            try {
                const pref = await get({ key: 'locale' });
                const locale = (pref === null || pref === void 0 ? void 0 : pref.value) || 'vi';
                console.log(`gtm.locale:${locale}`);
                await FirebaseAnalytics.setUserProperty({
                    key: 'language',
                    value: locale
                });
            }
            catch (e) {
                console.error(`gtm.locale:${e.message}`);
            }
        };
        // FirebaseAnalytics.logEvent({
        //   name: 'sign_up',
        //   params: { method: 'password' }
        // }).catch((e) => console.error(e))
        FirebaseAnalytics.setEnabled({
            enabled: true
        }).catch((e) => console.error(`gtm.setEnabled:${e.message}`));
        router === null || router === void 0 ? void 0 : router.afterEach(function (to) {
            Vue.nextTick(() => {
                setTimeout(() => {
                    setCurrentScreen(to);
                    setUserId();
                    setUserLocale();
                }, 250);
            });
        });
        const DataLayer = FirebaseAnalytics;
        DataLayer.push = async (data) => {
            try {
                await FirebaseAnalytics.logEvent({
                    name: data.event || 'test',
                    params: data
                });
            }
            catch (e) {
                console.error(`gtm.push:${e.message}`);
            }
        };
        window.dataLayer = DataLayer;
        inject('dataLayer', DataLayer);
    }
    else if (PLATFORM === 'web') {
        // router?.afterEach(function (to) {
        //   Vue.nextTick(() => {
        //     setTimeout(() => {
        //       console.log(to.path)
        //       console.log(to)
        //       console.log(head)
        //     }, 250)
        //   })
        // })
        const isEnabled = await FirebaseAnalytics.isEnabled();
        if (isEnabled.enabled) {
            FirebaseAnalytics.setEnabled({
                enabled: false
            }).catch((e) => console.error(`gtm.setEnabled:${e.message}`));
        }
    }
});
// import { log } from './gtm.utils'
// const _layer = 'dataLayer'
// const _id = 'GTM-55GRQDHT'
// function gtmClient(ctx, initialized) {
//   return {
//     init(id = _id) {
//       if (initialized[id] || !window._gtm_inject) {
//         return
//       }
//       window._gtm_inject(id)
//       initialized[id] = true
//       log('init', id)
//     },
//     push(obj) {
//       if (!window[_layer]) {
//         window[_layer] = []
//       }
//       window[_layer].push(obj)
//       log('push', obj)
//     }
//   }
// }
// function gtmServer(ctx, initialized) {
//   const events = []
//   const inits = []
//   ctx.beforeNuxtRender(() => {
//     if (!inits.length && !events.length) {
//       return
//     }
//     const gtmScript = ctx.app.head.script.find(s => s.hid == 'gtm-script')
//     gtmScript.innerHTML = `window['${_layer}']=${JSON.stringify(events)};${gtmScript.innerHTML}`
//     if (inits.length) {
//       gtmScript.innerHTML += `;${JSON.stringify(inits)}.forEach(function(i){window._gtm_inject(i)})`
//     }
//     const gtmIframe = ctx.app.head.noscript.find(s => s.hid == 'gtm-noscript')
//     const renderIframe = id => `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}&" height="0" width="0" style="display:none;visibility:hidden" title="gtm"></iframe>`
//     if (inits.length) {
//       gtmIframe.innerHTML += inits.map(renderIframe)
//     }
//   })
//   return {
//     init(id = _id) {
//       if (initialized[id]) {
//         return
//       }
//       inits.push(id)
//       initialized[id] = true
//       log('init', id)
//     },
//     push(obj) {
//       events.push(obj)
//       log('push', JSON.stringify(obj))
//     }
//   }
// }
// function startPageTracking(ctx) {
//   ctx.app.router.afterEach((to) => {
//     setTimeout(() => {
//       ctx.$gtm.push(to.gtm || {
//         routeName: to.name,
//         pageType: 'PageView',
//         pageUrl: '' + to.fullPath,
//         pageTitle: (typeof document !== 'undefined' && document.title) || '',
//         event: 'nuxtRoute'
//       })
//     }, 250)
//   })
// }
// export default function (ctx, inject) {
//   const runtimeConfig = (ctx.$config && ctx.$config.gtm) || {}
//   const autoInit = true
//   const id = 'GTM-55GRQDHT'
//   const runtimeId = runtimeConfig.id
//   const initialized = autoInit && id ? {[id]: true} : {}
//   const $gtm = process.client ? gtmClient(ctx, initialized) : gtmServer(ctx, initialized)
//   if (autoInit && runtimeId && runtimeId !== id) {
//     $gtm.init(runtimeId)
//   }
//   ctx.$gtm = $gtm
//   inject('gtm', ctx.$gtm)
//   if (process.client) { startPageTracking(ctx); }
// }
