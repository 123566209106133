import { computed, defineComponent, useStore, ref, onMounted, onUnmounted, useRoute } from '@nuxtjs/composition-api';
import { StatusBar } from '@capacitor/status-bar';
import { useWindowScroll } from '@vueuse/core';
import useDeepLink from '../composables/use-deeplink';
import useApp from '../composables/use-app';
const NAMESPACE = 'layout-content';
export default defineComponent({
    setup() {
        useDeepLink();
        StatusBar.setOverlaysWebView({ overlay: true });
        const el = ref(null);
        const { y } = useWindowScroll();
        const { getters } = useStore();
        const { padding } = useApp();
        const route = useRoute();
        const askQuestionDialog = ref(null);
        const shouldMinimize = ref(false);
        const minimizeOpacity = ref(0);
        const shouldShowAskQuestion = computed(() => route.value.path.includes('edu-hub'));
        const layoutContent = computed(() => getters.getField(NAMESPACE));
        const title = computed(() => { var _a; return ((_a = layoutContent.value) === null || _a === void 0 ? void 0 : _a.title) || route.value.query.title; });
        const height = computed(() => { var _a; return (_a = layoutContent.value) === null || _a === void 0 ? void 0 : _a.height; });
        const cover = computed(() => { var _a; return (_a = layoutContent.value) === null || _a === void 0 ? void 0 : _a.cover; });
        const eventBack = computed(() => { var _a; return (_a = layoutContent.value) === null || _a === void 0 ? void 0 : _a.eventBack; });
        const subtitle = computed(() => { var _a; return (_a = layoutContent.value) === null || _a === void 0 ? void 0 : _a.subtitle; });
        const scrollToTop = () => {
            var _a;
            (_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        const onScroll = () => {
            var _a, _b;
            minimizeOpacity.value =
                ((_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.scrollTop) / (padding.value.top + 56 + 24);
            if (((_b = document === null || document === void 0 ? void 0 : document.body) === null || _b === void 0 ? void 0 : _b.scrollTop) > padding.value.top + 56 + 24) {
                shouldMinimize.value = true;
            }
            else
                shouldMinimize.value = false;
        };
        onMounted(() => {
            var _a;
            (_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', onScroll);
        });
        onUnmounted(() => {
            var _a;
            (_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', onScroll);
        });
        return {
            el,
            y,
            padding,
            minimizeOpacity,
            shouldMinimize,
            shouldShowAskQuestion,
            askQuestionDialog,
            onScroll,
            scrollToTop,
            title,
            height,
            eventBack,
            cover,
            subtitle
        };
    }
});
