import { onMounted, onUnmounted, reactive, ref } from '@nuxtjs/composition-api';
export default function useFormIframe() {
    // Ref to store iframe element
    const iframe = ref(null);
    const state = reactive({});
    const data = ref();
    // Handle messages from iframe
    const handleMessage = (event) => {
        var _a;
        if (!iframe.value)
            return;
        if (event.source !== iframe.value.contentWindow)
            return;
        console.log(event, ' line 23');
        data.value = event.data;
        if ((_a = event.data) === null || _a === void 0 ? void 0 : _a.state) {
            Object.entries(event.data.state).forEach(([key, value]) => (state[key] = value));
        }
    };
    onMounted(() => window === null || window === void 0 ? void 0 : window.addEventListener('message', handleMessage));
    onUnmounted(() => window === null || window === void 0 ? void 0 : window.removeEventListener('message', handleMessage));
    return { iframe, state, data };
}
