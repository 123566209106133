import { computed, useStore } from '@nuxtjs/composition-api';
import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { getUserWalletsUrl } from '../url';
export function useUserWallets(option, _fetchOption) {
    const { get } = useWellcareApi();
    const { commit, state } = useStore();
    // eslint-disable-next-line dot-notation
    const wallets = computed(() => state['checkout']['wallets'] || []);
    const { onSuccess, response, loading: getUserWalletsLoading, execute: getUserWallets } = useRepository({
        fetcher: (params) => {
            return get({
                url: getUserWalletsUrl(params.user)
            });
        },
        conditions: option,
        useFetch: true,
        manual: false,
        toastOnError: true
    });
    onSuccess((response) => {
        if (response.code === 200 || response.code === 201) {
            const uniqueResults = new Set([...response.results]);
            commit('checkout/updateField', {
                path: 'wallets',
                value: Array.from(uniqueResults)
            });
        }
    });
    return { onSuccess, response, getUserWalletsLoading, wallets, getUserWallets };
}
