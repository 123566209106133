import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { computed, defineComponent, onMounted, ref, useStore, watch } from '@nuxtjs/composition-api';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import useApp from '~/composables/use-app';
export default defineComponent({
    setup() {
        const shouldShow = ref(false);
        const { getters } = useStore();
        const { padding } = useApp();
        const permissionsState = computed(() => getters.getField('permissions'));
        const currKey = ref(null);
        const alertContent = {
            notifications: {
                icon: '$bell',
                title: 'permission-alert-noti:title',
                message: 'permission-alert-noti:msg'
            },
            camera: {
                icon: '$alertOutline',
                title: 'permission-alert-camera:title',
                message: 'permission-alert-camera:msg'
            },
            microphone: {
                icon: '$alertOutline',
                title: 'permission-alert-mic:title',
                message: 'permission-alert-mic:msg'
            }
        };
        const openNativeSetting = async () => {
            await NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App
            });
            const listener = App.addListener('appStateChange', async ({ isActive }) => {
                if (isActive) {
                    await listener.remove();
                    shouldShow.value = false;
                }
            });
        };
        watch(permissionsState, (val) => {
            let permissionKey = null;
            if (typeof val === 'object' && Capacitor.isNativePlatform()) {
                for (const [key, status] of Object.entries(val)) {
                    console.log('[wellcare - debug] line 103 ' + key + ' ' + status);
                    if (status !== 'granted' && status !== 'unknown') {
                        console.log('[wellcare - debug] show alert line 105');
                        permissionKey = key;
                        break;
                    }
                }
            }
            if (permissionKey) {
                currKey.value = permissionKey;
                shouldShow.value = true;
            }
            else {
                currKey.value = null;
                shouldShow.value = false;
            }
        });
        onMounted(() => {
            if (typeof permissionsState.value === 'object' &&
                Capacitor.isNativePlatform()) {
                for (const [key, status] of Object.entries(permissionsState.value)) {
                    console.log('[wellcare - debug] line 125 ' + key + ' ' + status);
                    if (status !== 'granted' && status !== 'unknown') {
                        console.log('[wellcare - debug] show alert line 127');
                        currKey.value = key;
                        shouldShow.value = true;
                        break;
                    }
                }
            }
        });
        return {
            shouldShow,
            padding,
            alertContent,
            currKey,
            permissionsState,
            openNativeSetting
        };
    }
});
