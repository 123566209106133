import { onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { useLog } from '@wellcare/vue-component';
export function useConsultationSocket(user, onUpdate) {
    const { app } = useContext();
    const { log } = useLog();
    const socket = ref(null);
    const socketConnected = ref(false);
    const setupSocket = () => {
        socket.value = app.$nuxtSocket({
            name: 'Consultation',
            channel: `/Consultation`,
            forceNew: true
        });
        socket.value.on('connect', () => {
            log({
                message: 'User dashboard socket connected',
                context: 'useConsultationSocket'
            });
            socketConnected.value = true;
            socket.value.emit('join', {
                room: user.value._id,
                user: user.value._id
            });
        });
        socket.value.on('disconnect', () => {
            log({
                message: 'User dashboard socket disconnected',
                context: 'useConsultationSocket'
            });
            socketConnected.value = false;
        });
        const throttledUpdate = throttle(onUpdate, 1000);
        socket.value.on('created', throttledUpdate);
        socket.value.on('updated', throttledUpdate);
        socket.value.on('removed', throttledUpdate);
    };
    const disconnectSocket = () => {
        if (socket.value) {
            socket.value.disconnect();
            socket.value = null;
            socketConnected.value = false;
        }
    };
    onMounted(setupSocket);
    return {
        socketConnected,
        disconnectSocket
    };
}
function throttle(func, limit) {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}
