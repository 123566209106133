import { defineComponent, ref } from '@nuxtjs/composition-api';
export default defineComponent({
    setup() {
        const value = ref(false);
        const start = () => {
            value.value = true;
        };
        const finish = () => {
            value.value = false;
        };
        return {
            value,
            start,
            finish
        };
    }
});
