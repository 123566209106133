import { useRepository, useWellcareApi } from '@wellcare/nuxt-module-data-layer';
import { AXIOS_TIMEOUT } from '../../config';
import { getPersonalPatientDoctor } from '../../url';
export function useGetPersonalPatient(option) {
    const { get } = useWellcareApi();
    const { execute: executeGetPersonalPatient, onSucess: onSuccessGetPersonalPatient, loading } = useRepository({
        fetcher: () => {
            return get({
                url: getPersonalPatientDoctor(option.value),
                timeout: AXIOS_TIMEOUT,
                params: {
                    populate: JSON.stringify([
                        { path: 'related', select: 'avatar name' },
                        { path: 'provider' }
                    ]),
                    count: true,
                    pageSize: 50,
                    pageIndex: 1
                }
            });
        },
        useFetch: false,
        toastOnError: true
    });
    return {
        loading,
        executeGetPersonalPatient,
        onSuccessGetPersonalPatient
    };
}
