import { computed, watch } from '@nuxtjs/composition-api';
import { useElasticSearch } from '@wellcare/nuxt-module-notion/repositories';
import { useTransform } from './use-transform';
export const useSearchArticle = ({ source, slug }) => {
    const { hit } = useTransform();
    const searchArticle = computed(() => {
        return {
            query: {
                term: {
                    'page.properties.Slug.keyword': {
                        value: slug.value
                    }
                }
            },
            size: 1
        };
    });
    const { execute: executeSearch, hits, loading } = useElasticSearch(source, searchArticle);
    watch(slug, () => {
        if (slug.value) {
            executeSearch();
        }
    }, {
        immediate: true
    });
    const res = computed(() => {
        var _a;
        return ((_a = hits.value) === null || _a === void 0 ? void 0 : _a.length) ? hit(hits.value[0]) : {};
    });
    return {
        searchArticle,
        executeSearch,
        loading,
        res
    };
};
