import { defineComponent, computed } from '@nuxtjs/composition-api';
import useApp from '~/composables/use-app';
export default defineComponent({
    props: {
        error: {
            type: Object,
            default: () => { }
        }
    },
    setup() {
        const { padding } = useApp();
        const layoutStyle = computed(() => ({
            'padding-top': padding.value.top + 'px',
            'padding-left': padding.value.left + 'px',
            'padding-right': padding.value.right + 'px',
            height: '75vh'
        }));
        return { layoutStyle };
    }
});
