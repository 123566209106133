import { reactive, useContext, useRoute } from '@nuxtjs/composition-api';
export const useFilter = () => {
    const route = useRoute();
    const { i18n, $config } = useContext();
    const filterStatus = $config.eduHubStatus.split(',').map((status) => {
        return {
            match_phrase: {
                'page.properties.Status.keyword': status
            }
        };
    });
    const tagQuery = reactive({
        query: {
            bool: {
                must: [],
                filter: [
                    {
                        bool: {
                            should: [
                                {
                                    term: {
                                        'page.properties.Parent item.id.keyword': route.value.params.id
                                    }
                                }
                            ],
                            minimum_should_match: 1
                        }
                    },
                    filterStatus
                ]
            }
        }
    });
    const providerQuery = reactive({
        query: {
            bool: {
                must: [],
                filter: [
                    {
                        bool: {
                            should: [
                                {
                                    term: {
                                        'page.properties.Parent item.id.keyword': route.value.params.id
                                    }
                                }
                            ],
                            minimum_should_match: 1
                        }
                    },
                    {
                        bool: {
                            should: [
                                {
                                    term: {
                                        'page.properties.Status.keyword': {
                                            value: 'Published'
                                        }
                                    }
                                }
                            ],
                            minimum_should_match: 1
                        }
                    }
                ]
            }
        }
    });
    const videoQuery = reactive({
        sort: [
            {
                'page.properties.Score': {
                    order: 'desc',
                    unmapped_type: 'boolean'
                }
            },
            {
                'page.properties.Name.keyword': {
                    order: 'asc'
                }
            }
        ],
        size: 20,
        query: {
            bool: {
                must: [],
                filter: [
                    {
                        bool: {
                            should: [
                                {
                                    term: {
                                        'page.properties.Parent item.id.keyword': route.value.params.id
                                    }
                                }
                            ],
                            minimum_should_match: 1
                        }
                    },
                    filterStatus
                ]
            }
        }
    });
    // Function to transform aggregate
    const transformAggregate = (currentQuery, firstField, secondField) => {
        const aggs = {
            tags: {
                terms: { field: firstField }
            }
        };
        if (secondField) {
            aggs.ids = {
                terms: { field: secondField }
            };
        }
        return {
            size: 0,
            aggs,
            ...currentQuery
        };
    };
    // Function to transform filter
    const transformFilter = (value) => {
        return {
            bool: {
                should: [
                    {
                        term: value
                    }
                ],
                minimum_should_match: 1
            }
        };
    };
    // Function to remove filter
    const removeFilter = (filterList, key) => {
        const index = filterList.findIndex((filter) => {
            return filter.bool.should[0].term[key];
        });
        if (index !== -1) {
            filterList.splice(index, 1);
        }
    };
    // Function to add filter
    const addFilter = (filterList, key, value) => {
        if (value && value !== i18n.t('all')) {
            filterList.push(transformFilter({
                [key]: { value }
            }));
        }
    };
    // Function to update video filter
    const updateVideoFilter = (videoQuery, type, filterValue) => {
        const videoFilter = videoQuery.query.bool.filter;
        const fieldMap = {
            tag: 'page.properties.Tags.keyword',
            id: 'page.properties.Providers.properties._id.keyword'
        };
        const field = fieldMap[type];
        if (field) {
            removeFilter(videoFilter, field);
            addFilter(videoFilter, field, filterValue);
        }
        videoQuery.size = 10;
    };
    return {
        tagQuery,
        providerQuery,
        videoQuery,
        transformAggregate,
        transformFilter,
        removeFilter,
        addFilter,
        updateVideoFilter
    };
};
